@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

.main-color {
  background-color: #09274a;
}

body {
  font-family: "Roboto", sans-serif;
  direction: ltr;
}

.main_heading {
  font-size: 2.25rem; /* Text size equivalent to Tailwind's text-4xl */
  font-weight: 700; /* Bold text */
  text-align: center; /* Centered text */
  margin-top: 1.25rem; /* Equivalent to my-5 (top and bottom margin) */
  margin-bottom: 1.25rem; /* Equivalent to my-5 (top and bottom margin) */
}
