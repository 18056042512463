.orders {
    padding: 20px 80px;
}

.orders>h1 {
    margin: 30px 0;
}

@media screen and (max-width: 768px) {
    .orders {
        padding: 20px 40px;
    }
}

@media screen and (max-width: 460px) {
    .orders {
        padding: 20px 20px;
    }
}

.order__total {
    font-weight: 500;
    text-align: right;
    font-size: 18px;
}

.orders__order {
    border: 3px solid #eee;
    border-radius: 30px;
    padding: 20px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    -ms-border-radius: 30px;
    -o-border-radius: 30px;
}

.order {
    padding: 40px;
    margin: 20px 0;
    border: 1px solid lightgray;
    background-color: white;
    position: relative;
    border-radius: 1rem;
}

.order__id {
    position: absolute;
    top: 40px;
    right: 20px;
}

.order__total {
    font-weight: 500;
    text-align: right;
}



.checkoutProduct {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    align-items: center;
}

.checkoutProduct__info {
    padding-left: 20px
}

.checkoutProduct__info>button {
    background: var(--btn-color);
    border: 1px solid;
    margin-top: 1rem;
    border-color: #a88734 #9c7e31 #846a29;
    color: #111;
    padding: 4px 8px;
    cursor: pointer;
    border-radius: 0.3rem;

}

.checkoutProduct__info>button:hover {
    background: var(--btn-color-hover);
}

.checkoutProduct__image {
    object-fit: contain;
    width: 180px;
    height: 180px;
}

.checkoutProduct__rating {
    display: flex;
}

.checkoutProduct__title {
    font-size: 17px;
    font-weight: 800;
}

@media screen and (max-width: 468px) {
    .checkoutProduct {
        flex-direction: column;
    }

    .checkoutProduct__info {
        margin-top: 0.8rem;
    }
}